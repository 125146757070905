import Fuse from 'fuse.js';
import csvData1 from 'bundle-text:./products1.csv';
import csvData2 from 'bundle-text:./products2.csv';
import csvData3 from 'bundle-text:./products3.csv';
import csvData4 from 'bundle-text:./products4.csv';
import csvData5 from 'bundle-text:./products5.csv';
import { CSV } from './csv';

let params = (new URL(window.location.href)).searchParams;
export let currency = params.get("currency") || localStorage.getItem('currency') || 'USD';

localStorage.setItem('currency', currency);

let feed = params.get("feed") || localStorage.getItem('feed') || '1';
localStorage.setItem('feed', feed);

let buyer = params.get("buyer") || localStorage.getItem('buyer');

if (params.get("buyer") === null){
    buyer = "";
    localStorage.setItem('buyer', buyer);
}else{
    localStorage.setItem('buyer', buyer);
}


let csvData = {
    '1': csvData1,
    '2': csvData2,
    '3': csvData3,
    '4': csvData4,
    '5': csvData5, 
}[feed];




export const productsList = CSV.csvToObject(csvData).map((product: any) => {    
    const PRICE = product['PRICE_' + currency]
    PRICE ? parseFloat(PRICE.toString()) : 0

    return {
        sku: product.ITEM_NO.toString(),
        image: product.PICTURE_URL,
        name: product.ITEM_NAME,
        description: product.ITEM_DESCRIPTION,
        price: PRICE,
        category: [
            product.ItemMainGroup_nb,
            product.ItemCategoryGroup_nb,
            product.ItemFamilyGroup_nb,
        ].filter(it => it).join('/'),
        unit: (product.UOM || 'EA').toUpperCase(),
        unspsc: product.UNSPSC?.toString(),
        label: product['eco labels'],
        datasheets: product.datasheets,
        currency: currency,
        buyer : product.buyer
    }
}).filter(it => {
    if (buyer === "" ) {
        return  it.price > 0; 
    }
    return   it.buyer == buyer && it.price > 0; 
    
});




export function currencySymbol(currency: string) {
    return { 'USD': '$', 'EUR': '€' }[currency] || currency;
}

export interface Product {
    category: string;
    image: string;
    brand: string;
    description: any;
    sku: string;
    name: string;
    price: number;
    currency: string;

}
export interface CartItem {
    id: string;
    product: Product,
    quantity: number,
    options: any,
};

export class Cart {

    items: CartItem[] = [];
}

export class Model {

    cartClass = '';
    search: string = '';
    filter: any = {};

    products: Product[] = productsList;


    cart = new Cart();



    constructor() {
        if (localStorage.getItem('cart')) {
            this.cart = JSON.parse(localStorage.getItem('cart') as any);
        }
    }




}


export type Action
    = { type: 'add', product: Product, quantity: number, options: any }
    | { type: 'remove', product: Product }
    | { type: 'quantity', id: string, quantity: number }
    | { type: 'products', products: Product[] }
    | { type: 'filter', filter: any }
    | { type: 'filter.in', key: string, option: string, value: boolean }
    | { type: 'search', value: string }
    | { type: 'filter.clear' }
    | { type: 'cart.clear' }
    | { type: 'endanimate' };


let fuse = new Fuse([]);

export function RootReducer(state: Model, action: Action) {
    console.log(action);
    switch (action.type) {
        case 'cart.clear':
            state.cart.items = [];
            localStorage.setItem('cart', JSON.stringify(state.cart));
            break;
        case 'filter.clear':
            state.search = '';
            state.filter = {};
            break;
        case 'search':
            state.search = action.value;
            break;
        case 'add':
            const id = hash([action.product.sku, action.options]);
            const item = state.cart.items.find(it => it.id == id);
            if (item) {
                item.quantity += action.quantity;
            } else {
                state.cart.items.push({ ...action, id });
            }
            state.cartClass = 'animate__animated animate__headShake';

            localStorage.setItem('cart', JSON.stringify(state.cart));
            break;
        case 'quantity':
            // update quantity
            state.cart.items.filter(it => it.id == action.id).forEach(it => it.quantity = action.quantity);
            // remove if quantity is 0
            state.cart.items = state.cart.items.filter(it => it.quantity);

            localStorage.setItem('cart', JSON.stringify(state.cart));
            break;
        case 'products':
            state.products = [...action.products];
            fuse = new Fuse(state.products, { keys: ['name', 'sku', 'description'], findAllMatches: true, threshold: 0.4 });
            break;
        case 'filter':
            state.filter = action.filter;
            break;
        case 'filter.in':
            state.filter[action.key] = state.filter[action.key] || [];
            if (action.value) {
                state.filter[action.key].push(action.option);
            } else {
                state.filter[action.key] = state.filter[action.key].filter(it => it !== action.option);
            }
            if (state.filter[action.key].length == 0) {
                delete state.filter[action.key];
            }
            break;
        case 'endanimate':
            state.cartClass = '';
            break;
        default:
            break;
    }
    return { ...state };
}




export function search(state: Model) {

    let products = state.products;

    if (state.search) {
        products = fuse.search(state.search).map(it => it.item);
    }

    return products.filter(product => {
        for (const key in state.filter) {
            const value = state.filter[key];
            if (value == undefined) {
                continue;
            } else if (Array.isArray(value)) {
                if (value.includes(product[key])) {
                    continue;
                }
            } else if (value == product[key]) {
                continue;
            }
            return false;
        }
        return true;
    });
}

const hash = (str: any) => {
    if (typeof (str) !== 'string') {
        str = JSON.stringify(str);
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash; // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0].toString(36);
};


